<template>
    <ControlKnob
        v-model="value"
        class="is-knob is-unselectable"
        :options="knobOptions"
    >
    </ControlKnob>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//@ts-ignore (because vue-peaks does not provide types)
import ControlKnob from '@/components/controls/ControlKnob.vue';

/** A rotary know for manipulating values
 */
export default defineComponent({
    name: 'RotaryKnob',
    components: { ControlKnob },
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        maxValue: {
            type: Number,
            required: false,
            default: Number.MAX_VALUE,
        },
        minValue: {
            type: Number,
            required: false,
            default: Number.MIN_VALUE,
        },
        /** Class of the outer rim (outline of the knob) */
        rimClass: {
            type: String,
            required: false,
            default: '',
        },
        /** Class of the value-part of the rim, including the text (value of the knob) */
        valueClass: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    computed: {
        knobOptions(): Record<string, unknown> {
            return {
                imageSize: 40,
                showValue: false,
                hideDefaultValue: false,
                minValue: this.minValue,
                maxValue: this.maxValue,
                valueTextX: 50,
                valueTextY: 55,
                tickLength: 20,
                tickOffset: -8,
                tickStroke: 8,
                rimStroke: 4,
                valueArchStroke: 8,
                bgRadius: 40,
                rimClass: this.rimClass,
                bgClass: 'has-text-grey-dark',
                tickClass: this.valueClass,
                ariaLabel: 'Knob',
                svgClass: this.valueClass,
                valueTextClass: this.valueClass,
                valueArchClass: this.valueClass,
            };
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value: number) {
                this.$emit('update:modelValue', value);
            },
        },
    },
});
</script>
